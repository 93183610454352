import logo from "../pink-film.png";
import React from "react";

const Home = () => {
  return (
    <div className="App App-font">
      <header className="App-header div-flex">
        <div className="div-flex">
          <div className="div-flex">
            <div className="div-flex-child">
              <p className="title">sweet film</p>
            </div>
          </div>
          <div className="div-flex">
            <img src={logo} className="div-flex-child logo" alt="logo" />
          </div>
        </div>
        <div className="div-flex">
          <div className="div-flex">
            <div className="div-flex-child">
              <p className="description">
                download on the{" "}
                <a href="https://apps.apple.com/us/app/sweet-film/id1562768010">
                  app store
                </a>
              </p>
            </div>
          </div>
          <div className="div-flex">
            <div className="div-flex-child">
              <p className="description">
                connect with us on{" "}
                <a href="https://www.instagram.com/sweetfilm.app/">instagram</a>{" "}
                and <a href="https://www.tiktok.com/@sweetfilm.app">tik tok</a>
              </p>
            </div>
          </div>
          <div className="div-flex">
            <div className="div-flex-child">
              <p className="description">
                ask us questions at{" "}
                <a href="mailto:support@sweetfilm.app">support@sweetfilm.app</a>
              </p>
            </div>
          </div>

          {/* <p>and yeah</p> */}
        </div>
        <div className="div-flex">
          <p className="footer">
            &copy; Sweet Film LLC | <a href="/privacy-policy">privacy policy</a>
          </p>
        </div>
      </header>
    </div>
  );
};

export default Home;
