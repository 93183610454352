import React from "react";
import "./privacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <article>
      <header>
        <h1>Privacy Policy</h1>
      </header>
      <div>
        <p>
          This page is used to inform users of Sweet Film (hereafter may also be
          referred to as “We”, “Us”, &quot;Our&quot;) regarding our policies
          with the collection, use, and disclosure of personal information.
        </p>
        <h3>Your Consent</h3>
        <p>By using our apps, you consent to our Privacy Policy.</p>
        <h3>1. Your Data</h3>
        <p>
          We do not collect any personally identifiable data from the user. All
          media captured within the app is only stored locally on your device.
          As such, we do not have access to it.
        </p>
        <h3>2. Analytics</h3>
        <p>
          We use the following third-party services to collect anonymous usage
          data and crash reports to further improve the user experience of our
          app:
        </p>
        <ul>
          <li>
            <a href="https://firebase.google.com/support/privacy">
              Firebase Analytics
            </a>
          </li>
        </ul>
        <p>
          Use of Sweet Film is subject to the privacy policies linked above.
        </p>
        <h3>3. Testflight</h3>
        <p>
          Usage of Sweet Film’s beta builds through Testflight is subject to
          Testflight’s 
          <a href="https://www.apple.com/legal/internet-services/itunes/testflight/sren/terms.html">
            Privacy Policy
          </a>
          .
        </p>
        <h3>4. Apple</h3>
        <p>
          Sweet Film for iOS is distributed through the App Store and therefore
          the use of it is subject to Apple’s 
          <a href="https://www.apple.com/legal/privacy/en-ww/">
            Privacy Policy
          </a>
          .
        </p>
        <h3>
          5<strong>. Links to Other Sites</strong>
        </h3>
        <p>
          This service may contain links that direct you to third-party sites.
          We have no control over and assume no responsibility for the content,
          privacy policies, or practices of any third-party sites or services.
        </p>
        <h3>6. Contact</h3>
        <p>
          You can contact us regarding questions or concerns over this policy
          at: <a href="mailto:support@sweetfilm.app">support@sweetfilm.app</a>
        </p>
        <p></p>
      </div>
    </article>
  );
};

export default PrivacyPolicy;
