import "./App.css";
import Home from "./components/home";
import PrivacyPolicy from "./components/privacyPolicy";
import { Route, Switch, Redirect } from "react-router-dom";

function App() {
  return (
    <div>
      <Switch>
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route
          path="/app-store"
          component={() => {
            window.location.href =
              "https://apps.apple.com/us/app/sweet-film/id1562768010";
            return null;
          }}
        />
        <Route
          path="/instagram"
          component={() => {
            window.location.href = "https://www.instagram.com/sweetfilm.app/";
            return null;
          }}
        />
        <Route
          path="/tik-tok"
          component={() => {
            window.location.href = "https://www.tiktok.com/@sweetfilm.app";
            return null;
          }}
        />
        <Route path="/" component={Home} />
      </Switch>
    </div>
  );
}

export default App;
